var UserProfile = (function() {
      //var user_name = "";
      //var user_id ="";
    
      var getName = function() {
        //return user_name;  
        return localStorage.getItem('user_name') || '';  
        // Or pull this from cookie/localStorage 
      };
    
      var setName = function(name) {
        //user_name = name;     
        localStorage.setItem('user_name', name);
        // Also set this in cookie/localStorage  
      };


      var getId = function() {
            //return user_id;    
            return localStorage.getItem('user_id') || '';  
          };
        
      var setId= function(id) {
            //user_id = id;    
            localStorage.setItem('user_id', id); 
      };

      var logOut = function(){
            localStorage.setItem('user_id', ""); 
            localStorage.setItem('user_name', "");
      }
    
      return {
        getName: getName,
        setName: setName,
        getId: getId,
        setId: setId,
        logOut: logOut,
      }
    
    })();
    
    export default UserProfile;


//     

// 