/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================
ˇbove copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
    Row,
    Col,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import UserProfile from 'variables/UserProfile';
import { Redirect } from "react-router-dom";



class Travels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false,
            pages: 0,
            redirect: false
        };
    }


    componentWillMount() {
        if(UserProfile.getId()=="" || UserProfile.getId()==null){
            this.setState({ redirect: true});
        }
        var data={user: UserProfile.getId()};
        fetch(' https://a0jfo4efj2.execute-api.us-west-2.amazonaws.com/produccion/travelsuser', {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => res.json())
            .then((responseJson) => {
                //console.log(responseJson);
                this.setState({
                    items: responseJson,
                });
            });
    }


    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/'/>;
          }

        let items = this.state.items;
       
        const rows = items.map((item) => {

            var est = "ACTIVO";
            var now = new Date();
            var eventDate= new Date(item.fecha_inicio);
            
            if (now > eventDate) {
                est = "INACTIVO";
            } 
            
            return {
                destino: item.destino,
                precio: item.precio,
                salida: item.fecha_inicio + " " + item.hora_inicio,
                llegada: item.fecha_fin + " " + item.hora_fin,
                estatus: est,
                acciones: (<div><Link to={{ pathname: "/admin/add-travel/" + item.id, query: { id: item.id } }}>
                    <Button
                        color="link"
                        id={"edit" + item.id}
                        title=""
                        type="button"
                    >
                        <i className="tim-icons icon-pencil" />
                    </Button>
                </Link>
                    <UncontrolledTooltip
                        delay={0}
                        target={"edit" + item.id}
                        placement="right"
                    >
                        Editar
                </UncontrolledTooltip>
                    <Button
                        color="link"
                        id={"del" + item.id}
                        title=""
                        type="button"
                    >
                        <i className="tim-icons icon-trash-simple" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        target={"del" + item.id}
                        placement="right"
                    >
                        Eliminar
                </UncontrolledTooltip></div>),
            }


        });
        
        return (
            <>
                <div className="content">

                    <Row>
                        <Col  md="12">
                            <Card >
                                <CardHeader>
                                    <h4 className="title d-inline">VIAJES</h4>
                                    <Link to="/admin/add-travel/">
                                        <Button className="btn-fill pull-right" color="info" type="button">
                                            AGREGAR VIAJE
                                         </Button>
                                    </Link>

                                </CardHeader>
                                <CardBody>
                                    <div className="table-full-width table-responsive">
                                        <ReactTable
                                            data={rows}
                                            columns={[
                                                {
                                                    Header: "Destino",
                                                    accessor: "destino"
                                                },
                                                {
                                                    Header: "Precio",
                                                    accessor: "precio"
                                                },
                                                {
                                                    Header: "Fecha Salida",
                                                    accessor: "salida"
                                                },
                                                {
                                                    Header: "Fecha Llegada",
                                                    accessor: "llegada"
                                                },
                                                {
                                                    Header: "Estatus",
                                                    accessor: "estatus"
                                                },
                                                {
                                                    Header: "Acciones",
                                                    accessor: "acciones",
                                                    sortable: false,
                                                    filterable: false
                                                },
                                            ]}
                                            filterable= {true}
                                            defaultPageSize={10}
                                            previousText= 'Anterior'
                                            nextText= 'Siguiente'
                                            loadingText= 'Cargando...'
                                            noDataText= 'No se encontraron registros'
                                            pageText= 'Pagina'
                                            ofText= 'de'
                                            rowsText= 'filas'
                                            className="-striped -highlight"
                                        />


                                    </div>
                                    

                                </CardBody>
                                <CardFooter>
                                    
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Travels;


