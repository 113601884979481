/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import { Button } from "reactstrap";

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown show-dropdown"
    };
  }
  handleClick = () => {
    if (this.state.classes === "dropdown show-dropdown") {
      this.setState({ classes: "dropdown show-dropdown show" });
    } else {
      this.setState({ classes: "dropdown show-dropdown" });
    }
  };
  // activateMode = mode => {
  //   switch (mode) {
  //     case "light":
  //       document.body.classList.add("white-content");
  //       break;
  //     default:
  //       document.body.classList.remove("white-content");
  //       break;
  //   }
    
  // };
  render() {
    return (
      <div className="fixed-plugin">
        <div className={this.state.classes}>
          <div onClick={this.handleClick}>
            <i className="fa fa-info-circle " />
          </div>
          <ul className="dropdown-menu show">
            <li className="header-title">ATOMIKOD WANDERLUST</li>
            
            <li className="adjustments-line text-center color-change">
             
              <span className="color-label">
                Software desarrollado por 
              </span>
            </li>
            <li className="button-container">
              
              <Button
                color="default"
                block
                className="btn-round"
                outline
                href="#"
              >
                Documentación
              </Button>
            </li>
            
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;
