/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import FileBase64 from 'react-file-base64';
import UserProfile from 'variables/UserProfile';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  FormText,
  Input,
  Row,
  Col,
  // Carousel,
  // CarouselItem,
  // CarouselControl,
  // CarouselIndicators,
  FormFeedback
} from "reactstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { horario } from "variables/hours.jsx";


// const items = [
//   {
//     src: 'https://journey.app/blog/wp-content/uploads/2019/08/donde-esta-playa-balandra.jpg',
//   },
//   {
//     src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRwKmoQUDUghA1ngire7JYYLgvCAGN7GvzJaGysaGiTcQjqv0Px',
//   },
// ];

class AddTravel extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0,saving: false,image: "", travel_id: null,  files: [],
      travel: {"fecha_fin":"-",
              "precio": "0",
              "descripcion":"-",
              "destino":"-",
              "hora_inicio":"00:00",
              "lugar_salida":"-",
              "incluye":"-",
              "hora_fin":"00:00",
              "no_incluye":"-",
              "fecha_inicio":"-",
              "image":""
            } 
    };
    // this.next = this.next.bind(this);
    // this.previous = this.previous.bind(this);
    // this.goToIndex = this.goToIndex.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


  }

  getFiles(files){
    console.log(files[0]);
    var size = files[0].size.split(" ");
    if(size[0]>2400){
      Swal.fire(
        '',
        'La imagen es muy grande',
        'warning'
      )
      this.setState({ files: [], image: "" });
    }else{
      delete files[0].file;
    delete files[0].name;
    delete files[0].size;
    this.setState({ files: files[0], image: files[0].base64 });
    }
    
  }
  

  componentWillMount() {
    if (this.props.match.params.travelId) {
      this.setState({ travel_id: this.props.match.params.travelId });
      fetch('https://a0jfo4efj2.execute-api.us-west-2.amazonaws.com/produccion/travels/' + this.props.match.params.travelId, {
        method: 'GET'
      }).then(res => res.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({
            travel: responseJson,
            image: responseJson.image
          });
        });
    }
  }

  componentDidMount(){
    //console.log(this.state.travel);
  }

  handleSubmit(event) {
    
    event.preventDefault();
    this.setState({ saving: true }); 

    const formData = new FormData(event.target);
    var object = {};
    formData.forEach((value, key) => { object[key] = value });

    object["user"]=UserProfile.getId();
    object["image"]=this.state.travel.image;
    if(this.state.files.hasOwnProperty('base64')){

      object["new_files"]=this.state.files;
    }
    //console.log(this.state.files);
    if(object.destino==""){
      
    }
   
    var url = "https://a0jfo4efj2.execute-api.us-west-2.amazonaws.com/produccion/travels";
    var msj = "Registro exitoso";
    if (this.state.travel_id != null) {
      object.id = this.state.travel_id;
      //url += "/edit";
      msj = "Actualización exitosa";
    }
    //console.log(object);
    var data = JSON.stringify(object);
    //console.log(data);
    fetch(url, {
      method: 'POST',
      body: data,
    }).then(res => res.json())
      .then((responseJson) => {
        this.setState({ saving: false });
        if(responseJson.result=="ok"){
          Swal.fire({
            title: msj,
            text: '',
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.props.history.push('/admin/travels')
          })
        }
        
      })
  }

  // next() {
  //   if (this.animating) return;
  //   const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
  //   this.setState({ activeIndex: nextIndex });
  // }

  // previous() {
  //   if (this.animating) return;
  //   const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
  //   this.setState({ activeIndex: nextIndex });
  // }

  // goToIndex(newIndex) {
  //   if (this.animating) return;
  //   this.setState({ activeIndex: newIndex });
  // }

  

  render() {

  //   const onEmojiClick = (event, emojiObject) => {
  //     setChosenEmoji(emojiObject);
  // }


    const { image } = this.state;
    const { travel_id } = this.state;
    const { travel } = this.state;
    const { saving } = this.state;

    // const slides = items.map((item) => {
    //   return (
    //     <CarouselItem
    //       className="carouselImg"
    //       onExiting={this.onExiting}
    //       onExited={this.onExited}
    //       key={item.src}
    //     >
    //       <img src={item.src} alt={""} />
    //     </CarouselItem>
    //   );
    // });

    return (
      <>
        <div className="content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md="8">
                <Card>
                  <CardHeader>
                    <h5 className="title">
                      {travel_id ? 'EDITAR' : 'NUEVO'} VIAJE
                    </h5>
                  </CardHeader>
                  <CardBody>

                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Destino</label>
                          <Input
                            defaultValue={travel.destino}
                            placeholder="Nombre del destino"
                            type="text"
                            name="destino"
                            key={`destino${travel.destino}`}
                            
                          />
                          {/* <FormFeedback>Oh noes! that name is already taken</FormFeedback> */}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Precio por Persona</label>
                          <Input
                            defaultValue={travel.precio}
                            placeholder="Precio por persona"
                            type="number"
                            name="precio"
                            key={`precio${travel.precio}`}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Fecha Salida</label>
                          <Input
                            defaultValue={travel.fecha_inicio}
                            type="date"
                            name="fecha_inicio"
                            key={`fecha_inicio${travel.fecha_inicio}`}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="3">
                        <FormGroup>
                          <label>Hora de Salida</label>
                          <Input type="select" name="hora_inicio" defaultValue={travel.hora_inicio} key={`hora_inicio${travel.hora_inicio}`} >
                            {
                              horario.map((item) => {
                                return (
                                  <option key={item.value + "i"} value={item.text}>{item.text}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Fecha Llegada</label>
                          <Input
                            defaultValue={travel.fecha_fin }
                            key={`fecha_fin${travel.fecha_fin}`}
                            type="date"
                            name="fecha_fin"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="3">
                        <FormGroup>
                          <label>Hora de Llegada</label>
                          <Input type="select" name="hora_fin" defaultValue={travel.hora_fin} key={`hora_fin${travel.hora_fin}`}
                          >
                            {
                              horario.map((item) => {
                                return (
                                  <option key={item.value + "f"} value={item.text}>{item.text}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Lugar de Salida</label>
                          <Input
                            defaultValue={travel.lugar_salida}
                            placeholder="Lugar de Salida"
                            type="text"
                            name="lugar_salida"
                            key={`lugar_salida${travel.lugar_salida}`}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Descripción </label>
                          <Input
                            cols="80"
                            defaultValue={travel.descripcion}
                            placeholder="Escribe la descripcion del viaje"
                            rows="6"
                            type="textarea"
                            name="descripcion"
                            key={`descripcion${travel.descripcion}`}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Incluye </label>
                          <Input
                            cols="80"
                            defaultValue={travel.incluye}
                            placeholder="Escribe que incluye este viaje"
                            rows="1"
                            type="textarea"
                            name="incluye"
                            key={`incluye${travel.incluye}`}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>No Incluye </label>
                          <Input
                            cols="80"
                            defaultValue={travel.no_incluye}
                            placeholder="Escribe que NO incluye este viaje"
                            rows="1"
                            type="textarea"
                            name="no_incluye"
                            key={`no_incluye${travel.no_incluye}`}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                  </CardBody>
                  <CardFooter>
                  {!saving ? 
                  <Button className="btn-fill" color="info" type="submit">
                  Guardar
              </Button>
                  : 
                  <img style={{width: 50}} src={"https://www.avira.com/cache-buster-23424/static/common/images/loading.gif"} alt="Seleccione una imagen" />
                  }
                  
                    
                    
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">Imagen de Portada</h5>
                  </CardHeader>
                  <CardBody>
                    <CardText />
                    {/* <Carousel
                      activeIndex={activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                      {slides}
                      <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                      <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel> */}
                    <img src={image} alt="Seleccione una imagen" />
                    <br /> <br/>
                    <FormGroup>
                      
                      <FileBase64
        multiple={ true }
        onDone={ this.getFiles.bind(this) } />
                      {/* <FormText color="muted">
                        Puede seleccionar más de una imagen con la tecla crt o cmd y click izquierdo del mouse. <br />Las imagenes actuales serán reemplazadas.
                      </FormText> */}
                    </FormGroup>
                  </CardBody>
                  <CardFooter>

                  </CardFooter>
                </Card>
                
              </Col>
            </Row>
          </Form>
          
        </div>
      </>
    );
  }
}

export default AddTravel;
