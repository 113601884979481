/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Icons from "views/Icons.jsx";
import Map from "views/Map.jsx";
import Notifications from "views/Notifications.jsx";
import Travels from "views/Travels.jsx";
import Login from "views/Login.jsx";
import Typography from "views/Typography.jsx";
import UserProfile from "views/UserProfile.jsx";
import AddTravel from "views/AddTravel.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: "/admin",
    redirect: true
  },
  
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin",
    redirect: true
  },
 
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    redirect: true
  },
  
  {
    path: "/travels",
    name: "Viajes",
    icon: "tim-icons icon-bus-front-12",
    component: Travels,
    layout: "/admin"
  },
  {
    path: "/add-travel/:travelId?",
    name: "Formulario de Viaje",
    component: AddTravel,
    layout: "/admin",
    redirect: true
  },
];
export default routes;
