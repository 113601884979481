/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Button,
  Input,
  Row,
  Col,
  Alert
} from "reactstrap";
import { Redirect } from "react-router-dom";

import UserProfile from 'variables/UserProfile';


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = { redirect: false,alerta: false,mensaje:"Acceso Correcto",colAlert:""};
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if(UserProfile.getId()!="" || UserProfile.getId()!=null){
        //this.setState({ redirect: true});
    }
  }


  handleSubmit(event) {
    
    event.preventDefault();

    const formData = new FormData(event.target);
    var data = {};
    formData.forEach((value, key) => { data[key] = value });


    fetch('https://a0jfo4efj2.execute-api.us-west-2.amazonaws.com/produccion/user', {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then((responseJson) => {
        this.setState({ alerta: true })
        if(responseJson.result=="ok"){
          UserProfile.setName(data.user);
          UserProfile.setId(responseJson.userId);
          this.setState({ mensaje: "Acceso Correcto", colAlert: "success" })
          setTimeout(() => {  this.setState({ redirect: true }) }, 2000);
        }
        else{
          this.setState({ mensaje: "Usuario y/o Contraseña incorrecto", colAlert: "danger" })
        }
        
      })
    
  }

  render() {
    const { redirect } = this.state;
    const { alerta } = this.state;
    const { mensaje } = this.state;
    const { colAlert } = this.state;
    if (redirect) {
      return <Redirect to='/admin/travels'/>;
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="4"></Col>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle ><h2 className="title">WANDERLUST <small className="text-muted">by Atomikod</small></h2> </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <label>Usuario</label>
                      <Input
                        defaultValue=""
                        placeholder="Ingrese su usuario"
                        type="text"
                        name="user"

                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Contraseña</label>
                      <Input
                        defaultValue=""
                        placeholder="Ingrese su contraseña"
                        type="password"
                        name="pass"
                        
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <Button className="btn-fill" color="info" type="submit">
                          Acceder
                  </Button>
                      </Col>
                      <Col md="6">
                        
                      </Col>
                    </Row>
                    <br/>
                    {alerta ? 
                      <Alert color={colAlert}>
                      {mensaje}
                    </Alert>
                    : ""
                    }
                    

                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default Login;
